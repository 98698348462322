* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  list-style: none;
  user-select: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.err-msg {
  color: #ee6352;
  padding: 10px 15px;
}

.heroTitle {
  font-size: 5rem;
}
.heroSubTitle {
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 13vw;
  }
  .heroSubTitle {
    font-size: 8vw;
  }

  .bodyText {
    font-size: 5vw;
  }

  .videoResponsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  .videoResponsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

#lis500_pic {
  width: 80vw;
}

#thisSite_pic {
  width: 80vw;
}

#downloadResume {
  margin-top: 15vh;
}

.logoLink {
  width: 24vw;
  transition: width 0.5s;
}

.logoLink:hover {
  cursor: pointer;
  width: 26vw;
}

@media (max-width: 900px) {
  .logoLink {
    width: 50vw;
    transition: width 0.5s;
  }

  .logoLink:hover {
    cursor: pointer;
    width: 52vw;
  }
}

#videos {
  margin-left: 20vw;
}

#culversPic_ID {
  width: 70vw;
}

#homepageBackground {
  width: 100vw;
  margin-top: 30px;
}

ul {
  padding-left: 0 !important;
}

.hidden {
  visibility: hidden !important;
  height: 0px !important;
}

.lostPenguin {
  height: 20rem;
}
.notFoundPageContainer {
  padding-top: 15vh;
}
