.educationIcon {
  max-width: 35% !important;
}

.aboutImage {
  height: 30rem !important;
  transform: scale(1);
  transition: transform 0.5s ease;
}

.aboutImage:hover {
  transform: scale(1.1);
}
