.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#eddieMainLogo {
  height: 3rem;
}

@media (min-width: 992px) {
  .nav-link {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    font-size: 1.5rem !important;
  }
}

#pageLogo {
  display: flex;
  font-size: 2rem;
}

#pageLogo > div {
  padding-left: 1rem;
}

@media (max-width: 600px) {
  #pageLogo > div {
    visibility: hidden;
    width: 0;
  }
}

@media (max-width: 767.98px) {
  .info-wrap {
    height: 400px;
  }
}

.contactItemImg {
  width: 60px;
  margin-bottom: 20px;
}

.contactPageTitle {
  margin-bottom: 20px !important;
}

#formImage {
  background-image: url("./images/contactPage/conversationIcon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.btn-group {
  display: inline !important;
}

.pageContainer {
  position: relative !important ;
  min-height: 100vh !important ;
}

.contentWrap {
  padding-bottom: 5rem !important ; /* Footer height */
}

.footer {
  position: absolute !important ;
  bottom: 0 !important ;
  width: 100% !important ;
  height: 5rem !important ; /* Footer height */
}

.animatedDiv {
  box-shadow: none;
  transition: box-shadow 0.2s ease;
}

.animatedDiv:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.biggerLoadingSpinner {
  height: 5rem !important;
  width: 5rem !important;
}

.itemBoxes > div > div > a > div {
  height: 14rem;
  width: auto;
}
