.portfolioCoverImage {
  width: 50rem !important;
  transform: scale(1);
  transition: transform 0.5s ease;
}

.portfolioCoverImage:hover {
  transform: scale(1.1);
}

.cardLoader {
  width: 5rem !important;
  height: 5rem !important;
}

.sr-only {
  visibility: hidden;
  width: 0px;
}

.dropdown-menu {
  width: 18rem !important;
  text-align: center !important;
}
.dropdown-toggle {
  width: 18rem !important;
}
